import React from 'react';

import styled from '@emotion/styled';

import { laptopQuery, desktopQuery, tabletQuery, mobileQuery } from '../utils/mediaqueries';

import { isIphoneXDisplay, isInStandaloneMode } from '../utils/functions';

const isIphoneX = isIphoneXDisplay() && isInStandaloneMode() ? '75px' : '55px';

const StyledExcerptWrapper = styled.div(
  {
    marginTop: '100vh',
    marginBottom: isIphoneX,
    display: 'block',
    zIndex: 0,

    [laptopQuery]: {
      display: 'flex',
      flexDirection: 'column',
      width: '26.5%',
      margin: 0,
      marginLeft: '6.5%',
      height: '100%',
    },

    [desktopQuery]: {
      display: 'flex',
      flexDirection: 'column',
      width: '26.5%',
      margin: 0,
      marginLeft: '6.5%',
    },
  },
  ({ hide, isIphoneX }) => ({
    marginBottom: isIphoneX,

    [mobileQuery]: {
      display: hide ? 'none' : 'block',
    },

    [tabletQuery]: {
      display: hide ? 'none' : 'block',
    },
  }),
);

const ExcerptWrapper = ({ hide, children }) => (
  <StyledExcerptWrapper isIphoneX={isIphoneX} hide={hide}>
    {children}
  </StyledExcerptWrapper>
);

export default ExcerptWrapper;
