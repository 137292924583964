import React from 'react';

import styled from '@emotion/styled';
import theme from '../utils/theme';

import { mobileQuery, tabletQuery, laptopQuery, desktopQuery } from '../utils/mediaqueries';

import ExcerptWrapper from '../components/ExcerptWrapper';
import Header from '../components/Header';
import Layout from '../components/layout/Layout';
import ModuleButton from '../components/ModuleButton';
import ModulePageContentWrapper from '../components/ModulePageContentWrapper';
import NavMenu from '../components/NavMenu';
import NavExcerpt from '../components/NavExcerpt';
import Seo from '../components/Seo';
import { createLink } from '../utils/functions';
import { facultyDescriptions, facultyImages } from '../seo/MainPageSeo';

const Heading = styled.h1({
  color: theme.colors.mainText,
  [mobileQuery]: {
    margin: '60px 0 20px 35px',
  },

  [tabletQuery]: {
    marginLeft: '35px',
  },

  [laptopQuery]: {
    margin: 0,
    marginBottom: '30px',
  },

  [desktopQuery]: {
    margin: 0,
    marginBottom: '30px',
  },
});

const Modules = styled.div({
  display: 'flex',
  width: '100%',
  flexWrap: 'wrap',

  [mobileQuery]: {
    alignItems: 'center',
    flexDirection: 'column',
  },

  [tabletQuery]: {
    margin: '0 35px',
  },
});

const ModulesListTemplate = ({ pageContext, uri }) => {
  const { studyExcerpts: navExcerpts, modules, faculty } = pageContext;

  const metaDescription = facultyDescriptions[createLink(faculty)];
  const metaImage = facultyImages[createLink(faculty)];

  const renderExcerpts = () =>
    navExcerpts.map(excerpt => (
      <NavExcerpt
        currentUrl={uri}
        key={excerpt.heading}
        heading={excerpt.heading}
        description={excerpt.description}
        thumbnail={excerpt.thumbnail}
        to={excerpt.to}
      />
    ));

  const renderModules = modules.map(({ title }) => (
    <ModuleButton key={title} padding="0 10px" sm title={title} to={`${uri}/${createLink(title)}`} />
  ));

  return (
    <>
      <Seo title={`Programy | ${faculty}`} description={metaDescription} image={metaImage} />
      <Layout>
        <NavMenu hide />
        <ExcerptWrapper hide>{renderExcerpts()}</ExcerptWrapper>
        <ModulePageContentWrapper fixed>
          <Header sticky displayDesktop backButton />
          <Heading>PROGRAMY</Heading>
          <Modules>{renderModules}</Modules>
        </ModulePageContentWrapper>
      </Layout>
    </>
  );
};

export default ModulesListTemplate;
